import {LitElement, css, html} from 'lit-element';
import { theme } from '../theme.js';


class LinIcon extends LitElement {
    static get properties() {
        return {
            faIcon: {type: String, attribute: "fa-icon", reflect: true},
            deviconIcon: {type: String, attribute: "devicon-icon", reflect: true}
        }
    }

    static get styles() {
        return [
            theme,
            css`
                :host([sm]) .icon-size {
                    font-size: 2em;
                }

                :host([xsm]) .icon-size {
                    font-size: 1em;
                }

                :host([md]) .icon-size {
                    font-size: 3em;
                }

                :host([lg]) .icon-size {
                    font-size: 6em;
                }

                :host([plain]) .icon-size:hover {
                    transform: none;
                }

                :host .icon-size {
                    font-size: 3em;
                    margin: 0px 4px;
                    transition: all .3s linear;
                }

                :host .icon-size:hover {
                    transform: scale(1.1);
                }

                :host([reversed-hover]) .icon-size:hover {
                    color: var(--light-gray);
                }
            `
        ]
    }

    constructor() {
        super();
        this.faIcon = "";
        this.deviconIcon = "";
    }

    render() {
        return html`
            <link rel="stylesheet" href="./vendors/css/fontawesome/font-awesome.css">
            <link rel="stylesheet" href="./vendors/css/devicon/devicon.css">
            <link rel="stylesheet" href="./resources/css/components.css">
            <div class="icon-block">
                ${this.faIcon != "" ? 
                    html`
                        <div class="icon-size fa fab fa-${this.faIcon}"></div>
                    `
                    :
                    html`
                        <div class="icon-size devicon-${this.deviconIcon}-plain"></div>
                    `
                }
                
            </div>
        `
    }
}


customElements.define('lin-icon', LinIcon);