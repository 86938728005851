import { LitElement, html, css } from "lit-element";
import { theme } from '../theme.js';
import '../lin-icon/lin-icon.js';

class LinCard extends LitElement {
    static get properties() {
        return {
            icon: {type: String}
        }
    }

    static get styles() {
        return [
            theme,
            css `
                :host {
                    width: 100%;
                }
                :host #content{
                    color: var(--text-color-gray);
                }

                .card {
                    background: var(--light-gray);
                    box-shadow: 0 0 20px rgba(0,0,0,0.4);
                    width: calc(100% - 40px);
                    border-radius: 5px;
                    margin: 0px 20px 20px 20px;
                    padding: 20px;
                    text-align: center;
                    color: white;
                    float: left;
                }

                .separator {
                    width: 60%;
                    height: 3px;
                    background-color: var(--theme);
                    margin: 10px 20%;
                    text-align: center;
                }
                
                .icon {
                    margin: 30px 0;
                }
            `
        ]
    }

    render() {
        return html`
            <div class="card">
                <div class="title">
                    <slot name="title"></slot>
                    <div class="separator"></div>
                </div>
                <div class="icon">
                    <lin-icon fa-icon="${this.icon}"></lin-icon>
                </div>
                <div id="content">
                    <slot name="content"></slot>
                </div>
            </div>
        `
    }
}

customElements.define('lin-card', LinCard);