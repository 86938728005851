import {LitElement, css, html} from 'lit-element';


class LinHeading extends LitElement {
    static get properties() {
        return {
            type: {type: String, reflect: true},
            title: {type: String}
        }
    }

    static get styles() {
        return css`
        `
    }

    constructor() {
        super();
        this.type = "h1";
        this.title = html``;
    }

    _renderTitle() {
        switch(this.type.toLocaleLowerCase()) {
            case 'h2':
                this.title = html`
                    <h2><slot></slot></h2>
                `
                break;
            case 'h3':
                this.title = html`
                    <h3><slot></slot></h3>
                `
                break;
            case 'h4':
                this.title = html`
                    <h4><slot></slot></h4>
                `
                break;
            case 'h5':
                this.title = html`
                    <h5><slot></slot></h5>
                `
                break;
            case 'h6':
                this.title = html`
                    <h6><slot></slot></h6>
                `
                break;
            default:
                this.title = html`
                    <h1><slot></slot></h1>
                `
                break;
        }
    }

    attributeChangedCallback(attr, oldVal, newVal) {
        super.attributeChangedCallback(attr, oldVal, newVal);
        if (attr == 'type') {
            this._renderTitle();
        }
    }

    render() {
        return html`
            <link rel="stylesheet" href="./vendors/css/bootstrap/bootstrap.css">
            <link rel="stylesheet" href="./resources/css/components.css">
            ${this.title}
            <div class="underline-hightlight"></div>
        `
    }
}


customElements.define('lin-heading', LinHeading);