import {LitElement, css, html} from 'lit-element';
import { theme } from '../theme.js';

class LinDivider extends LitElement {
    static get properties() {
        return {
            
        }
    }
    
    static get styles() {
        return [
            theme,
            css`
            :host {
                display: flex;
                justify-content: center;
                align-items: center;
                clear: both;
            }
            :host > div {
                width: 100%;
                height: 1px;
                background-color: var(--light-gray);
                display: inline-block;
                margin-bottom: 1em;
            }

            :host([section]) > div{
                border: 0;
                background-color: transparent;
                height: 0;
                margin: 2rem auto 4rem;
                text-align: center;
                width: 100%;
            }

            :host([section]) > div::before {
                background-color: var(--light-gray);
                content: '';
                height: 2px;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                width: 10%;
                transform: rotate(10deg);
            }

            :host([section]) > div::after {
                background-color: var(--light-gray);
                content: '';
                height: 2px;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                width: 10%;
                transform: rotate(-10deg);
            }
            `
        ]
    }
    
    
    render() {
        return html`
        <div></div>
        `
    }
}

customElements.define('lin-divider', LinDivider);