
import {css} from 'lit-element';

export const theme = css`
    :host {
		--black: #000;
		--white: #fff;
		--gray: #1a1a1a;
		--light-gray: #333333;

		--text-color-gray: #818181;
		--text-color-gray-header: #aeaeae;
		--text-color-gray-border: #7a7a7a;

		--theme-green: #7dba5c;
		--theme-orange: #de6f32;
		--theme-light-blue: #7fc9fa;
		--theme: var(--theme-light-blue);

    }
`;
